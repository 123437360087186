<template>
  <v-scale-transition>
    <kits-panel
      :kits-items="royaltyIncentiveKits"
      :breadcrumbs="breadcrumbs"
    >
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        {{ title }}
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
const title = 'RI'

export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Incentives', disabled: false, to: '/incentives',
        },
        {
          text: title, disabled: true,
        },
      ],
      royaltyIncentiveKits: [
        {
          icon: 'mdi-calendar-blank',
          path: '/monthly_royalty_incentive_sld',
          title: 'Monthly Summary',
          desc: 'Monthly royalty incentive summary.',
        },
        {
          icon: 'mdi-calendar-text',
          path: '/daily_royalty_incentive_sld',
          title: 'Daily Details',
          desc: 'Daily details of reloads.',
        },
      ],
      title,
    }
  },
}
</script>
